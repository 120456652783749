import { v4 as uuidv4 } from "uuid";

// Import images

// Hover images
import RobynLynchCollab from "./media/r_images_centered/RLC_Hover_centre.png";
import CantoOstinato from "./media/r_images_centered/Canto_Ostinato_Hover_02.png";
import CultureNight22 from "./media/r_images_centered/CultureNight22_01_centre.png";
import ANSOKassel from "./media/r_images_centered/ANSO-Kassel-03.png";
import ComplexGig from "./media/r_images_centered/Complex_gig_01_centre.png";
import BDsupport from "./media/r_images_centered/Lightmoves_02_centre.png";
import MotionStudy from "./media/r_images_centered/Motion-Study_centre_01.png";
import SoundObjects from "./media/r_images_centered/SoundObjects-2.gif";
import StorytellingAcrossMedia from "./media/r_images_centered/StorytellingAcrossMedia-2.gif";
import RiverPoem from "./media/r_images_centered/RiverPoem.png";
import SynapseRelapse from "./media/r_images_centered/Synapse-Relapse.png";
import Titan from "./media/r_images_centered/Titan.jpg";
import DHxNGV from "./media/r_images_centered/DHxNGV.jpg";
import CommonGround from "./media/r_images_centered/Common-Ground.png";
import SkinLiquidState from "./media/r_images_centered/SkinLiquidState_04.gif";

// Robyn Lynch Collaboration
import RobynLynch01 from "./media/r_projects/robyn-lynch-collab/RLC_01.jpg";
import RobynLynch02 from "./media/r_projects/robyn-lynch-collab/RLC_02.jpg";
import RobynLynch03 from "./media/r_projects/robyn-lynch-collab/RLC_03.jpg";
import RobynLynch04 from "./media/r_projects/robyn-lynch-collab/RLC_04.jpg";

// Canto Ostinato
import CantoOstinato01 from "./media/r_projects/canto-ostinato/01.jpg";
import CantoOstinato02 from "./media/r_projects/canto-ostinato/02.jpg";
import CantoOstinato03 from "./media/r_projects/canto-ostinato/03.jpg";
import CantoOstinato04 from "./media/r_projects/canto-ostinato/04.jpg";

// Greywood Arts
import GreywoodArts01 from "./media/r_projects/greywood-arts/01.png";
import GreywoodArts02 from "./media/r_projects/greywood-arts/02.png";
import GreywoodArts03 from "./media/r_projects/greywood-arts/03.png";
import GreywoodArts04 from "./media/r_projects/greywood-arts/04.png";
import GreywoodArts05 from "./media/r_projects/greywood-arts/05.png";
import GreywoodArts06 from "./media/r_projects/greywood-arts/06.png";
import GreywoodArts07 from "./media/r_projects/greywood-arts/07.png";
import GreywoodArts08 from "./media/r_projects/greywood-arts/08.png";

// ANSO @ Kassel
import ANSOKassel01 from "./media/r_projects/anso-kassel/01.jpg";
import ANSOKassel02 from "./media/r_projects/anso-kassel/02.jpg";
import ANSOKassel03 from "./media/r_projects/anso-kassel/03.jpg";
import ANSOKassel04 from "./media/r_projects/anso-kassel/04.jpg";
import ANSOKassel05 from "./media/r_projects/anso-kassel/05.jpg";
import ANSOKassel06 from "./media/r_projects/anso-kassel/06.jpg";
import ANSOKassel07 from "./media/r_projects/anso-kassel/07.jpg";
import ANSOKassel08 from "./media/r_projects/anso-kassel/08.jpg";
import ANSOKassel09 from "./media/r_projects/anso-kassel/09.jpg";
import ANSOKassel10 from "./media/r_projects/anso-kassel/01.png";

// Complex Gallery Dublin
import ComplexGig1 from "./media/r_projects/complex-gig/Performance-at-Complex-Gallery-01.jpg";
import ComplexGig2 from "./media/r_projects/complex-gig/Performance-at-Complex-Gallery-02.jpg";
import ComplexGig3 from "./media/r_projects/complex-gig/Performance-at-Complex-Gallery-03.jpg";
import ComplexGig4 from "./media/r_projects/complex-gig/Performance-at-Complex-Gallery-04.jpg";

// Motion Study
import MotionStudy01 from "./media/r_projects/motion-study/MS-01.png";
import MotionStudy02 from "./media/r_projects/motion-study/MS-02.jpg";
import MotionStudy03 from "./media/r_projects/motion-study/MS-03.jpg";
import MotionStudy04 from "./media/r_projects/motion-study/MS-04.jpg";
import MotionStudy05 from "./media/r_projects/motion-study/MS-05.jpg";

// Spiral @ Lightmoves
import Spiral1 from "./media/r_projects/spiral/Spiral-01.jpg";
import Spiral2 from "./media/r_projects/spiral/Spiral-02.jpg";
import Spiral3 from "./media/r_projects/spiral/Spiral-03.jpg";
import Spiral4 from "./media/r_projects/spiral/Spiral-04.jpg";
import Spiral5 from "./media/r_projects/spiral/Spiral-05.jpg";
import Spiral6 from "./media/r_projects/spiral/Spiral-06.jpg";
import Spiral7 from "./media/r_projects/spiral/Spiral-07.jpg";
import Spiral8 from "./media/r_projects/spiral/Spiral-08.jpg";
import Spiral9 from "./media/r_projects/spiral/Spiral-09.jpg";
import Spiral10 from "./media/r_projects/spiral/Spiral-10.jpg";
import Spiral11 from "./media/r_projects/spiral/Spiral-11.jpg";

// Synapse Relapse
import SR01 from "./media/r_projects/synapse-relapse/sr-01.jpg";
import SR02 from "./media/r_projects/synapse-relapse/sr-02.jpg";
import SR03 from "./media/r_projects/synapse-relapse/sr-03.jpg";
import SR04 from "./media/r_projects/synapse-relapse/sr-04.jpg";
import SR05 from "./media/r_projects/synapse-relapse/sr-05.jpg";
import SR06 from "./media/r_projects/synapse-relapse/sr-06.jpg";
import SR07 from "./media/r_projects/synapse-relapse/sr-07.jpg";
import SR08 from "./media/r_projects/synapse-relapse/sr-08.jpg";
import SR09 from "./media/r_projects/synapse-relapse/sr-09.jpg";
import SR10 from "./media/r_projects/synapse-relapse/sr-10.jpg";
import SR11 from "./media/r_projects/synapse-relapse/sr-11.jpg";

// SAM
import SAM01 from "./media/r_projects/sam/SAM-01.jpg";
import SAM02 from "./media/r_projects/sam/SAM-02.jpg";
import SAM03 from "./media/r_projects/sam/SAM-03.jpg";
import SAM04 from "./media/r_projects/sam/SAM-04.jpg";

// Skin Liquid State
import SLS01 from "./media/r_projects/skin-liquid-state/SLS-01.jpg";
import SLS02 from "./media/r_projects/skin-liquid-state/SLS-02.jpg";
import SLS03 from "./media/r_projects/skin-liquid-state/SLS-03.jpg";
import SLS04 from "./media/r_projects/skin-liquid-state/SLS-04.jpg";
import SLS05 from "./media/r_projects/skin-liquid-state/SLS-05.jpg";

// Gasometer
import GASO01 from "./media/r_projects/gasometer/Gasometer-01.jpg";
import GASO02 from "./media/r_projects/gasometer/Gasometer-02.jpg";

function ProjectList_array() {
  return [
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// ROBYN LYNCH x KEPLER x PAURIC FREEMAN
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    {
      project_title: "Robyn Lynch Collaboration",
      media_type: "Installation",
      imageURL: RobynLynchCollab,
      id: uuidv4(),
      section: (
        <div className="project-info">
          <div className="text-body">
            <h6>Robyn Lynch X Kelper X Pauric Freeman</h6>
            <p>2023</p>
            <p>NOW Gallery</p>
            <p>London, UK</p>
            <p>Installation</p>
            <p>
              Collaboration artwork with Robyn Lynch, Kepler Interactive, and
              Pauric Freeman for Robyn Lynch's exhibition 'Greeting from
              Ireland' at NOW Gallery, London. Images courtesy of NOW Gallery.
            </p>
            <img
              src={RobynLynch03}
              alt="Robyn Lynch X Kepler X Pauric Freeman"
            />
            <img
              src={RobynLynch01}
              alt="Robyn Lynch X Kepler X Pauric Freeman"
            />
            <img
              src={RobynLynch02}
              alt="Robyn Lynch X Kepler X Pauric Freeman"
            />
            <img
              src={RobynLynch04}
              alt="Robyn Lynch X Kepler X Pauric Freeman"
            />
          </div>
        </div>
      ),
    },

    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// Canto Ostinato
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    {
      project_title: "Canto Ostinato",
      media_type: "Live Video",
      imageURL: CantoOstinato,
      id: uuidv4(),
      section: (
        <div className="project-info">
          <div className="text-body">
            <h6>Canto Ostinato</h6>
            <p>2023</p>
            <p>The Sugar Club</p>
            <p>Dublin, Ireland</p>
            <p>Live Video</p>
            <p>Music: Canto Ostinato, performed by Jurgen Simpson</p>
            <img src={CantoOstinato03} alt="Live Video" />
            <img src={CantoOstinato02} alt="Live Video" />
            <img src={CantoOstinato04} alt="Live Video" />
            <img src={CantoOstinato01} alt="Live Video" />
          </div>
        </div>
      ),
    },

    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// ANSO @ Galeria Kollektiva
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    {
      project_title: "ANSO @ Galeria Kollektiva",
      media_type: "Audiovisual Performance",
      imageURL: ANSOKassel,
      id: uuidv4(),
      section: (
        <div className="project-info">
          <div className="text-body">
            <h6>ANSO @ Galeria Kollektiva</h6>
            <p>2022</p>
            <p>Galeria Kollektiva</p>
            <p>Kassel, Germany</p>
            <p>Audiovisual performance</p>
            <p>
              Site-specific performances on the 26th and 27th of August at
              Galeria Kollektiva, Kassel, during the documenta15 festival.
            </p>

            {/* <img
              src={ANSOKassel05}
              alt="Live Audiovisual Performance at Galeria Kollektiva, Kassel"
            /> */}
            <img
              src={ANSOKassel06}
              alt="Live Audiovisual Performance at Galeria Kollektiva, Kassel"
            />
            <img
              src={ANSOKassel03}
              alt="Live Audiovisual Performance at Galeria Kollektiva, Kassel"
            />
            <img
              src={ANSOKassel10}
              alt="Live Audiovisual Performance at Galeria Kollektiva, Kassel"
            />
            <img
              src={ANSOKassel07}
              alt="Live Audiovisual Performance at Galeria Kollektiva, Kassel"
            />
            <img
              src={ANSOKassel09}
              alt="Live Audiovisual Performance at Galeria Kollektiva, Kassel"
            />
          </div>
        </div>
      ),
    },

    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// Culture Night '22
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    {
      project_title: "Culture Night '22",
      media_type: "Audiovisual Performance",
      imageURL: CultureNight22,
      id: uuidv4(),
      section: (
        <div className="project-info">
          <div className="text-body">
            <h6>Culture Night '22</h6>
            <p>2022</p>
            <p>Greywood Arts</p>
            <p>Killeagh, Co. Cork, Ireland</p>
            <p>Audiovisual performance (recording)</p>
            <p>
              Recorded performance for Greywood Arts Centre, Culture Night '22.
            </p>
            <img src={GreywoodArts06} alt="Live Video" />
            <img src={GreywoodArts01} alt="Live Video" />
            <img src={GreywoodArts07} alt="Live Video" />
            <img src={GreywoodArts03} alt="Live Video" />
            <img src={GreywoodArts08} alt="Live Video" />

            <img src={GreywoodArts04} alt="Live Video" />
          </div>
        </div>
      ),
    },

    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// It's At Home
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    {
      project_title: "Embodied Live @ The Complex",
      media_type: "Audiovisual Performance",
      imageURL: ComplexGig,
      id: uuidv4(),
      section: (
        <div className="project-info">
          <div className="text-body">
            <h6>Embodied Live @ The Complex</h6>
            <p>2021</p>
            <p>The Complex Gallery</p>
            <p>Dublin, Ireland</p>
            <p>Audiovisual performance</p>
            <p>HD video projection, stereo audio</p>
            <p>
              Live performance for <i>It's at Home</i> at The Complex Gallery,
              Dublin, supported by the Arts Council of Ireland.
            </p>
            <img
              src={ComplexGig1}
              alt="Live Audiovisual Performance at the Complex Gallery, Dublin"
            />
            <img
              src={ComplexGig2}
              alt="Live Audiovisual Performance at the Complex Gallery, Dublin"
            />
            <img
              src={ComplexGig3}
              alt="Live Audiovisual Performance at the Complex Gallery, Dublin"
            />
            <img
              src={ComplexGig4}
              alt="Live Audiovisual Performance at the Complex Gallery, Dublin"
            />
          </div>
        </div>
      ),
    },

    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// Spiral @ Light Moves
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    {
      project_title: "Spiral @ Lightmoves Festival",
      media_type: "Audiovisual Performance",
      imageURL: BDsupport,
      id: uuidv4(),
      section: (
        <div className="project-info">
          <div className="text-body">
            <h6>Spiral @ LightMoves Festival</h6>
            <p>2021</p>
            <p>Lightmoves Festival of Screendance</p>
            <p>Limerick, Ireland</p>
            <p>Audiovisual performance</p>
            <p>HD video projection, stereo audio</p>
            <p>
              Live audiovisual performance at Lightmoves Festival, support act
              for Beatrice Dillon. Supported by the Arts Council of Ireland.
            </p>
            <img
              src={Spiral4}
              alt="Live Audiovisual Performance at Lightmoves Festival"
            />
            <img
              src={Spiral3}
              alt="Live Audiovisual Performance at Lightmoves Festival"
            />
            <img
              src={Spiral6}
              alt="Live Audiovisual Performance at Lightmoves Festival"
            />
            <img
              src={Spiral7}
              alt="Live Audiovisual Performance at Lightmoves Festival"
            />
            <img
              src={Spiral5}
              alt="Live Audiovisual Performance at Lightmoves Festival"
            />
            <img
              src={Spiral1}
              alt="Live Audiovisual Performance at Lightmoves Festival"
            />
            <img
              src={Spiral10}
              alt="Live Audiovisual Performance at Lightmoves Festival"
            />
          </div>
        </div>
      ),
    },

    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// Skin Liquid State
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    {
      project_title: "Skin Liquid State",
      media_type: "Audiovisual Composition",
      imageURL: SkinLiquidState,
      id: uuidv4(),
      section: (
        <div className="project-info">
          <div className="text-body">
            <h6>Skin Liquid State</h6>
            <p>2020</p>
            <p>Creative Code Festival</p>
            <p>New York, USA</p>
            <p>Audiovisual composition</p>
            <p>Video, 2 channel audio</p>
            <p>3650 x 1200</p>
            <p>
              Audiovisual composition, displayed at the Creative Code Festival,
              New York.
            </p>
            <img src={SLS01} alt="Skin Liquid State" />
            <img src={SLS02} alt="Skin Liquid State" />
            <img src={SLS03} alt="Skin Liquid State" />
            <img src={SLS04} alt="Skin Liquid State" />
          </div>
        </div>
      ),
    },

    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// Motion Study
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    {
      project_title: "Motion Study",
      media_type: "Audiovisual Composition",
      imageURL: MotionStudy,
      id: uuidv4(),
      section: (
        <div className="project-info">
          <div className="text-body">
            <h6>Motion Study</h6>
            <p>2020</p>
            <p>Audiovisual compositions</p>
            <p>Video, 2 channel audio</p>
            <img src={MotionStudy05} alt="Motion Study" />
            <img src={MotionStudy03} alt="Motion Study" />
            <img src={MotionStudy02} alt="Motion Study" />
          </div>
        </div>
      ),
    },

    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// River Poem
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    {
      project_title: "River Poem",
      media_type: "Installation",
      imageURL: RiverPoem,
      id: uuidv4(),
      section: (
        <div className="project-info">
          <div className="text-body">
            <h6>River Poem</h6>
            <p>2020</p>
            <p>Maynooth University</p>
            <p>Kildare, Ireland</p>
            <p>Installation</p>
            <p>Projection mapping, 3D printed model, 2 channel audio</p>
            <p>
              Machine learning and digital poetry installation, at Maynooth
              University, Ireland. Supported by Science Foundation Ireland.
            </p>
            <div class="videoWrapper">
              <iframe
                title="vimeo-player"
                src="https://player.vimeo.com/video/538133830?h=26efe129ff"
                width="1920"
                height="1080"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      ),
    },

    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// Synapse Relapse
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    {
      project_title: "Synapse Relapse",
      media_type: "Installation",
      imageURL: SynapseRelapse,
      id: uuidv4(),
      section: (
        <div className="project-info">
          <div className="text-body">
            <div className="synapse-relapse">
              <h6>Synapse Relapse</h6>
              <p>2017</p>
              <p>Gertrude Street Projection Festival</p>
              <p>Melbourne, Australia</p>
              <p>Generative art installation</p>
              <p>Video Projection</p>
              <p>6h duration</p>
              <p>
                Generative art installation selected for the Gertrude Street
                Projection Festival 10 Year Anniversary, Melbourne.
              </p>
              <div class="videoWrapper">
                <iframe
                  title="vimeo-player"
                  src="https://player.vimeo.com/video/219342409?h=508f0762f7"
                  width="1920"
                  height="384"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>

              <script src="https://player.vimeo.com/api/player.js"></script>
              <img
                src={SR02}
                alt="Synapse Relapse at Gertrude Street projection Festival, Melbourne"
              />
              <img
                src={SR11}
                alt="Synapse Relapse at Gertrude Street projection Festival, Melbourne"
              />
              <img
                src={SR07}
                alt="Synapse Relapse at Gertrude Street projection Festival, Melbourne"
              />
              <img
                src={SR08}
                alt="Synapse Relapse at Gertrude Street projection Festival, Melbourne"
              />
              <img
                src={SR09}
                alt="Synapse Relapse at Gertrude Street projection Festival, Melbourne"
              />
              <img
                src={SR10}
                alt="Synapse Relapse at Gertrude Street projection Festival, Melbourne"
              />
            </div>
          </div>
        </div>
      ),
    },

    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// Common Ground
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    {
      project_title: "Common Ground",
      media_type: "Installation",
      imageURL: CommonGround,
      id: uuidv4(),
      section: (
        <div className="project-info">
          <div className="text-body">
            <h6>Common Ground</h6>
            <p>2016</p>
            <p>Gasometer Music Venue</p>
            <p>Melbourne, Australia</p>
            <p>Installation</p>
            <p>2 channel projection installation</p>
            <p>4 hour duration</p>
            <p>
              Generative projection installation at the Gasometer Music Venue,
              Melbourne.
            </p>
            <img src={GASO01} alt="Gasometer Installation" />
            <img src={GASO02} alt="Gasometer Installation" />
          </div>
        </div>
      ),
    },
  ];

  // /////////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////// Storytelling Across Media
  // /////////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////////////
  // {
  //   project_title: "Storytelling Across Media",
  //   media_type: "Web Design",
  //   imageURL: StorytellingAcrossMedia,
  //   id: uuidv4(),
  //   section: (
  //     <div className="project-info">
  //       <div className="text-body">
  //         <h6>Storytelling Across Media</h6>
  //         <p>2021</p>
  //         <p>Website development</p>
  //         <p>
  //           Website development for Storytelling Across Media research centre
  //           at Maynooth University, Ireland. Developed in collaboration with
  //           <a href="https://treeburns.com/" target="_blank">
  //             <u>Tree Burns</u>
  //           </a>
  //           .
  //         </p>
  //         <img src={SAM01} alt="Storytelling Across Media" />
  //         <img src={SAM03} alt="Storytelling Across Media" />
  //         <img src={SAM02} alt="Storytelling Across Media" />
  //         <img src={SAM04} alt="Storytelling Across Media" />
  //       </div>
  //     </div>
  //   ),
  // },

  // /////////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////// Titan
  // /////////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////////////
  // {
  //   project_title: "Titan",
  //   media_type: "AR application",
  //   imageURL: Titan,
  //   id: uuidv4(),
  // },

  // /////////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////// Algorithm x Alumina
  // /////////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////////////
  // {
  //   project_title: "Algorithm: Alumina",
  //   media_type: "Install Production (w/ Algorithm)",
  //   imageURL: DHxNGV,
  //   id: uuidv4(),
  // },

  // /////////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////// Ed Sheeran x Algorithm
  // /////////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////////////
  // {
  //   project_title: "Ed Sheeran x Algorithm",
  //   media_type: "Install Production (w/ Algorithm)",
  //   imageURL: DHxNGV,
  //   id: uuidv4(),
  // },

  // /////////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////////////
  // //////////////////////////////// David Hockney x NGV
  // /////////////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////////////
  // {
  //   project_title: "Hockney x NGV",
  //   media_type: "Install Production (w/ NGV)",
  //   imageURL: DHxNGV,
  //   id: uuidv4(),
  // },
}

export default ProjectList_array;
